// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-latest-page-js": () => import("./../src/templates/latest-page.js" /* webpackChunkName: "component---src-templates-latest-page-js" */),
  "component---src-templates-photography-page-js": () => import("./../src/templates/photography-page.js" /* webpackChunkName: "component---src-templates-photography-page-js" */),
  "component---src-templates-previous-work-page-js": () => import("./../src/templates/previous-work-page.js" /* webpackChunkName: "component---src-templates-previous-work-page-js" */)
}

